@import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,700&display=swap');

$font: "Noto Sans";
$backgroundColor: #303030;
$divColor: #202020;

$headerImgTint: rgba(0, 0, 0, .3);
$navTint: rgba(0, 0, 0, .1);
$headerImgTintInvisible: rgba(0, 0, 0, 0);

// media queries
$phone: "only screen and (max-device-width:500px) and (orientation: portrait)";
$tabletPortrait: "only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1)";

// mixins
@mixin centerContent {
    display:flex;
    justify-content:center;
}

// Reset
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 62.5%;
	font: inherit;
    vertical-align: baseline;
    // border:1px solid gray;
    box-sizing:border-box;
}

body {
    font-family:$font;
    background-color:$backgroundColor;
    color:white;
    height:100%;
    h1, h2, h3, h4, h5, h6 {
        font-weight:bold;
    }
    h1 {
        font-size:4rem;
    }
    h2 {
        font-size:2.5rem;
        padding:20px;
    }
    h3 {
        font-size:2rem;
    }
    p {
        font-weight:lighter;
    }
    a, a:visited {
        text-decoration:none;
        transition:.3s;
        &.project-link {
            display:flex;
            align-items:center;
            color:gainsboro;
            @media #{$phone} {
                i {
                    display:none;
                }
                color:cornflowerblue;
                &:hover {
                    color:royalblue;
                }
                h3 {
                    width:100%;
                }
            }
        }
        &:hover {
            color:white;
        }
        &.primary-link {
            color:cornflowerblue;
            &:hover {
                color:royalblue;
            }
        }
        i.icon {
            font-size:1.6rem;
            margin-left:.5rem;
        }
    }
    header {
        height:100vh;
        background-color:inherit;
        background-image: url("./header-bg.jpg");
        background-size: cover;
        .bg-tint {
            height:100%;
            background-color:$headerImgTint;
            display:flex;
            flex-direction:column;
            // justify-content:center;
            // justify-content:space-between;
            opacity:1;
            transition:1.5s;
            nav {
                // position:fixed;
                display:flex;
                justify-content:center;
                align-items:center;
                fill:white;
                background-color:$navTint;
                opacity:1;
                transition:.5s;
                font-size:1.1rem;
                color:gainsboro;
                width:100%;
                min-height:70px;
                height:70px;
                div {
                    width:150px;
                    @include centerContent;
                    transition:.5s;
                    height:100%;
                    align-items:center;
                    &:hover, &.active-link {
                        cursor:pointer;
                        color:white;
                        background-color:$navTint;
                    }
                }
                &.invisible {
                    opacity:0;
                }
                @media #{$phone} {
                    flex-direction:column;
                    height:200px;
                    div {
                        width:100%;
                        border-bottom:1px solid white;
                        &.active-link {
                            font-weight:bold;
                        }
                    }
                }
            }
            .header-content {
                display:flex;
                flex-direction:column;
                justify-content:center;
                height:100%;
                opacity:1;
                transition:1s;
                margin-left:2.5%;
                .header-social {
                    margin-top:2%;
                    display:flex;
                    align-items:center;
                    justify-content:space-between;
                    width:30%;
                    height:2rem;
                    a i.icon {
                        font-size:3.3rem;
                        color:white;
                        transition:.2s;
                        margin:0;
                        &:hover {
                            font-size:3.8rem;
                        }
                    }
                    // svg {
                    //     width:50px;
                    //     height:50px;
                    //     fill:white;
                    //     transition:.3s;
                    //     &:hover {
                    //         cursor:pointer;
                    //         width:65px;
                    //         height:65px;
                    //     }
                    // }
                    // > a div {
                    //     margin-right:50px;
                    //     @media #{$phone} {
                    //         margin-right:0;
                    //     }
                    // }
                    @media #{$phone} {
                        width:100%;
                        justify-content:space-between;
                        padding:10px;
                        margin-top:5%;
                        svg {
                            width:60px;
                            height:60px;
                        }
                    }
                }
                .header-text {
                    width:100%;
                    display:flex;
                    flex-direction:column;
                    justify-content:center;
                    p {
                        font-size:2rem;
                        width:60%;
                    }
                    h1 {
                        font-size:2.5rem;
                        margin:0;
                    }
                    @media #{$phone} {
                        width:100%;
                        text-align:center;
                        p {
                            width:100%;
                        }
                    }
                }
                @media #{$phone} {
                    margin:0;
                }
            }
            .header-content-invisible {
                opacity:0;
            }
        }
        .bg-tint-invisible {
            background-color:$headerImgTintInvisible;
        }
    }
    .projects, .about-me, .skills {
        display:flex;
        flex-direction:column;
        align-items:center;
        overflow:hidden;
        .project {
            width:100%;
            background-color:$divColor;
            padding:40px;
            display:flex;
            overflow:hidden;
            ul {
                margin:.5rem 1rem;
                font-size:1.2rem;
                @media #{$phone} {
                    margin-left:1.5rem;
                }
            }
            .project-image {
                margin-left:60px;
                width:400px;
                transition:.3s;
                opacity:.8;
                display:flex;
                align-items: center;
                a.mobile {
                    display:flex;
                    align-items: baseline;
                    max-width:100%;
                    img:first-of-type {
                        width:80%;
                    }
                    img:last-of-type {
                        margin-left:10px;
                        width:20%;
                    }
                }
                img {
                    width:100%;
                }
                &:hover {
                    opacity:1;
                    cursor:pointer;
                }
                @media #{$phone} {
                    width:100%;
                    margin:0;
                    padding:20px;
                    justify-content:center;
                }
                @media #{$tabletPortrait} {
                    width:600px;
                }
            }
            &:last-of-type {
                margin-bottom:40px;
            }
            @media #{$phone} {
                padding:.2rem;
            }
            @media #{$tabletPortrait} {
                padding:10px 0;
            }
            .project-info {
                padding:0 40px;
                width:100%;
                .github-links {
                    margin-top:20px;
                    @media #{$phone} {
                        margin-bottom:20px;
                    }
                }
                p {
                    font-size:1.2rem;
                    &.github {
                        font-size:1rem;
                        font-weight:bold;
                        @media #{$phone} {
                            font-size:1.3rem;
                            text-align:center;
                        }
                    }
                }
                .tags {
                    display:flex;
                    .tag {
                        margin:10px 10px 0 0;
                        padding:3px 10px;
                        display:flex;
                        justify-content:center;
                        font-size:1rem;
                        color:gainsboro;
                        border:2px solid gainsboro;
                        border-radius:15px;
                        min-width:70px;
                        font-weight:bold;
                    }
                    @media #{$phone} {
                        justify-content:center;
                        flex-wrap:wrap;
                    }
                }
                @media #{$phone} {
                    padding:0 10px;
                    h3 {
                        text-align:center;
                        font-size:1.8rem;
                        margin-bottom:10px;
                    }
                }
            }
            &:not(:first-of-type) {
                margin-top:40px;
            }
            @media #{$phone} {
                flex-direction: column;
                align-items:center;
                justify-content:center;
            }
        }
        .about-me-container {
            display:flex;
            justify-content:center;
            align-items:center;
            height:60vh;
            .about-me-div {
                height:80%;
                background-color:$divColor;
                display:flex;
                width:100%;
                font-size:1.3rem;
                padding:5% 10%;
                align-items:center;
                img {
                    width:20%;
                    border-radius:5px;
                }
                .about-me-info {
                    width:80%;
                    margin-left:5%;
                    p:not(:first-child) {
                        margin-top:10px;
                    }
                }
            }
            @media #{$phone} {
                height:100%;
                .about-me-div {
                    flex-direction:column;
                    width:100%;
                    font-size:1.5rem;
                    padding:0;
                    // text-align:center;
                    img {
                        width:100%;
                        padding:3%;
                    }
                    .about-me-info {
                        align-items:center;
                        margin:0;
                        width:95%;
                        padding:1%;
                    }
                }
            }
            @media #{$tabletPortrait} {
                .about-me-div {
                    width:100%;
                    font-size:1.6rem;
                    img {
                        width:40%;
                    }
                }
            }
        }
        .skills-container {
            background-color:$divColor;
            width:100%;
            padding:50px 100px;
            position:relative;
            margin-bottom:40px;
            .skills-icons {
                display:flex;
                justify-content:space-between;
                @media #{$phone} {
                    flex-wrap:wrap;
                    width:100%;
                }
            }
            @media #{$phone} {
                padding:.8rem;
            }
            .skills-text {
                font-size:1.5rem;
                margin-top:20px;
            }
            .greyed-out {
                color:gray;
            }
            .indented {
                > ul {
                    margin-left:100px;
                    ul {
                        margin-left:75px;
                        @media #{$phone} {
                            margin-left:40px;
                        }
                    }
                    @media #{$phone} {
                        margin-left:20px;
                    }
                }
            }
            .title-display {
                position:absolute;
                // font-size:1.2rem;
                top:0;
                margin:10px 10px 0 0;
                padding:3px;
                display:flex;
                justify-content:center;
                font-size:1rem;
                color:gainsboro;
                border:2px solid gainsboro;
                border-radius:15px;
                width:70px;
                font-weight:bold;
                transition:.2s;
                opacity:1;
                @media #{$phone} {
                    opacity:0;
                }
            }
            .hidden {
                opacity:0;
            }
        }
    }
    .about-me {
        margin-bottom:80px;
        @media #{$phone} {
            height:auto;
        }
    }
    .skills {
        // height:85vh;
        @media #{$phone} {
            height:auto;
        }
    }
    .resume {
        display:flex;
        flex-direction:column;
        align-items:center;
        width:100%;
        overflow:hidden;
        .download-box {
            display:flex;
            align-items:center;
            justify-content:space-between;
            font-weight:bold;
            color:gainsboro;
            background-color:$divColor;
            padding:1.5rem;
            width:28rem;
            margin-bottom:2rem;
            font-size:1.1rem;
            overflow:hidden;
            i {
                font-size:3rem;
            }
            &:hover {
                cursor:pointer;
                color:white;
                background-color:black;
            }
            @media #{$phone} {
                justify-content: flex-start;
                width:100%;
                padding:1.5rem .2rem;
                i {
                    font-size:2.3rem;
                }
            }
        }
        @media #{$phone} {
            height:65vh;
            justify-content:center;
            .react-pdf__Document {
                display:none;
            }
        }
    }
    footer {
        width:100%;
        padding:10px;
        background-color:$divColor;
        align-items:center;
        flex-direction:column;
        @include centerContent();
        .footer-social {
            display:flex;
            a {
                margin:0 10px;
            }
        }
        .copyright {
            letter-spacing: 5px;
            text-transform:uppercase;
        }
        @media #{$phone} {
            font-size:1.5rem;
            text-align:center;
            .footer-social {
                a {
                    margin:0;
                }
                display:none;
            }
        }
    }
}